import { bindTouchAndClickEvent } from '@/shared/event';
import { hidePlaceholder } from '@/shared/misc/placeholder';
import { ing } from '@/shared/fai';
import { ing as mobiIng } from '@/shared/tips';
import { closeLoading, showLoading } from '@/preview/index';
import { bindImgLazyLoad } from '@/shared/imageLazyload';
import { getNewsV3Comment } from '@/api/news';

import { request } from '@/api/request';
import { showMemberLoginMsg } from '@/shared/member';
import { logMallFdp, logMonitor } from '@/shared/log';
import { dayjs } from '@jz/utils';
import pagenation from '@/components/pagenation.vue';
import { bindCommImgShow } from './product/swipe';
import { COL } from '@/def/col';
import { mobiHideScroll } from '@/shared/util';
import { addRequestPrefix, encodeUrl } from '@/shared/util';
import { mobiShowScroll } from '@/shared/util';
import { preprocessUrl } from '@/shared/url';
import { clueReuqestVm } from '@jz/biz-shared';
import { encodeHtml } from '@/shared/util';
import { MobileAuthPopupService, MOBILE_AUTH_MESSAGE_TYPES } from '@/services';
import { getThemeColor } from '@/shared/theme-color';
const FROM_CHANGE_PAGE = 'changePage';

const setNewCommImgBindShow = function () {
    setTimeout(function () {
        var listNumObject = jm('#tabPanel3 .msgArea').eq(0).find('.ct-s-content-m').eq(0)[0];
        if (typeof listNumObject != 'undefined' && listNumObject != 'null') {
            var images = listNumObject.querySelectorAll('img');
            if (typeof images != 'undefined' && images != null && images.length > 0) {
                bindCommImgShow(images, false, 0);
            }
        }
    }, 500);
};

const addTheNewComment = function (data) {
    var msgArea = [];
    var creatorType = 'VIP';
    if (data.newsId != null) {
        if (data.memberId == 0) {
            creatorType = LS.Incognito;
        }
        var date = new Date(data.createTime);
        date = dayjs(date).format('YYYY-MM-DD');
        msgArea.push("<div class='msgArea'>");
        msgArea.push("  <div class='msgTitle'>");
        msgArea.push(
            "  <div class='msgUser'><span class='g_mainColor g_main_color_v3'>[" +
                creatorType +
                ']</span>' +
                data.creator +
                '</div>'
        );
        msgArea.push("  <div class='msgTime'>" + date + '</div>');
        msgArea.push('  </div>');
        msgArea.push("  <div class='msgContent'>" + data.comment + '</div>');
        msgArea.push('</div>');
        //将新添加文章评论添加到评论列表的顶部
        if (jm('.nnewsComHeader').length != 0) {
            jm(msgArea.join('')).insertAfter(jm('.nnewsComHeader'));
        } else {
            location.reload();
        }
    } else {
        if (data.memberId == 0) {
            creatorType = LS.Incognito;
        } else {
            creatorType = data.level;
        }
        var _date = new Date(data.createTime);
        _date = dayjs(_date).format('YYYY-MM-DD');
        var r = jm('html').css('font-size');
        r = r.substring(0, r.length - 2);
        var headPic = data.headPic;
        msgArea.push("<div class='msgArea ct-panel productCommentPanel'>");
        msgArea.push("<div class='pdMsgTitle'>");
        msgArea.push('<div class="msgUserHeacPic"><img src="' + headPic.path + '" ');
        if (headPic.thumbId) {
            var width = headPic.width,
                left = headPic.left,
                top = headPic.top,
                imgW = headPic.imgW,
                imgH = headPic.imgH;
            var rate = width / (r * 1.8);
            msgArea.push(
                'style="top:' +
                    -top / rate +
                    'px; left:' +
                    -left / rate +
                    'px; width:' +
                    imgW / rate +
                    'px; height:' +
                    imgH / rate +
                    'px"'
            );
        } else {
            msgArea.push('style="height:' + r * 1.8 + 'px"');
        }
        msgArea.push(' /></div>');
        msgArea.push("<div class='msgUser g_mirrorColor'>&nbsp;" + data.creator + '</div>');
        msgArea.push("<span class='msgLevalUser'></span>");
        msgArea.push("<div class='msgTime g_mirrorColor'>" + _date + '</div>');
        msgArea.push('</div>');
        if ('star' in data) {
            msgArea.push("<div class='fk-star-list'>");
            var star = Number(data.star);
            for (var i = 0; i < 5; i++) {
                if (i < star) {
                    msgArea.push("<li class='faisco-icons-star3 select_more'></li>");
                } else {
                    msgArea.push("<li class='faisco-icons-star3 select_less'></li>");
                }
            }
            msgArea.push('</div>');
            var oldCount;
            if (star == 1) {
                oldCount = Number(jm('.fk-right-statisBox .badComm').text());
                jm('.fk-right-statisBox .badComm').text(oldCount + 1);
            } else if (star > 3) {
                oldCount = Number(jm('.fk-right-statisBox .hignComm').text());
                jm('.fk-right-statisBox .hignComm').text(oldCount + 1);
            } else {
                oldCount = Number(jm('.fk-right-statisBox .middComm').text());
                jm('.fk-right-statisBox .middComm').text(oldCount + 1);
            }
            var avgScore = jm('.fk-comm-avgScore'),
                totalScore = Number(avgScore.attr('_tc')) + star,
                totalSize = Number(avgScore.attr('_ts')) + 1;
            var statisScore = totalScore / totalSize;
            avgScore.text(statisScore.toFixed(1));
            avgScore.attr('_tc', totalScore);
            avgScore.attr('_ts', totalSize);
        }
        msgArea.push("<div class='msgContent g_defaultColor'>" + data.comment + '</div>');
        if (typeof data.commImgList != 'undefined' && data.commImgList != 'null') {
            var commImgList = jm.parseJSON(data.commImgList);
            if (commImgList != null && commImgList.length > 0) {
                msgArea.push("<div class='ct-s-content-m' style='padding:0;'>");
                msgArea.push('<table>');
                msgArea.push('<tr>');
                for (var _i6 = 0; _i6 < commImgList.length; _i6++) {
                    msgArea.push("<td class='s-img-f-tb'>");
                    msgArea.push("<table class='s-img-tb' cellpadding='0' cellspacing='0'>");
                    msgArea.push('<tr>');
                    msgArea.push("<td class='s-img-bd' valign='middle'   align='center'>");
                    msgArea.push("<img src='" + commImgList[_i6].path + "' alt='' class='s-img-set'>");
                    msgArea.push('</td>');
                    msgArea.push('</tr>');
                    msgArea.push('</table>');
                    msgArea.push('</td>');
                }
                msgArea.push('</tr>');
                msgArea.push('</table>');
                msgArea.push('</div>');
            }
        }
        msgArea.push('</div>');
        //将新添加商品评论添加到评论列表的顶部
        if (jm('#tabPanel3 .msgArea')[0] != undefined) {
            jm(msgArea.join('')).insertBefore(jm('#tabPanel3 .msgArea')[0]);
            setNewCommImgBindShow();
            if (jm('#tabPanel3 .noCommentListDescribe').length) {
                jm('#tabPanel3 .noCommentListDescribe').parent().remove();
            }
        } else {
            location.reload();
        }
    }
    //关闭评论页面
    jm('#userCommentPanel .userCommentGoBack').click();
};

const addTheNewCommentV3 = function (data) {
    var msgArea = [];
    var creatorType = 'VIP';
    let date = null;

    if (data.newsId != null) {
        if (data.memberId == 0) {
            creatorType = LS.Incognito;
        }
        date = new Date(data.createTime);
        date = dayjs(date).format('YYYY-MM-DD');
        msgArea.push("<div class='msgArea'>");
        msgArea.push("  <div class='msgTitle'>");
        msgArea.push("  <div class='userInfoBox'>");
        msgArea.push(
            "    <div class='msgUser'><span class='g_mainColor g_main_color_v3'>[" +
                creatorType +
                ']</span>' +
                data.creator +
                '</div>'
        );
        msgArea.push('  </div>');
        msgArea.push("  <div class='msgTime'>" + date + '</div>');
        msgArea.push('  </div>');
        msgArea.push("  <div class='msgContent'>" + data.comment + '</div>');
        msgArea.push('</div>');
        //将新添加文章评论添加到评论列表的顶部

        if (jm('.nnewsComHeader').length != 0) {
            jm(msgArea.join('')).insertAfter(jm('.nnewsComHeader'));
        } else {
            location.reload();
        }
    } else {
        if (data.memberId == 0) {
            creatorType = LS.Incognito;
        } else {
            creatorType = data.level;
        }
        date = new Date(data.createTime);
        date = dayjs(date).format('YYYY-MM-DD');
        var r = jm('html').css('font-size');
        r = r.substring(0, r.length - 2);
        var headPic = data.headPic;
        msgArea.push("<div class='msgArea ct-panel productCommentPanel'>");
        msgArea.push("<div class='pdMsgTitle'>");
        msgArea.push('<div class="msgUserHeacPic"><img src="' + headPic.path + '" ');
        if (headPic.thumbId) {
            var width = headPic.width,
                left = headPic.left,
                top = headPic.top,
                imgW = headPic.imgW,
                imgH = headPic.imgH;

            var rate = width / (r * 1.25);
            msgArea.push(
                'style="top:' +
                    -top / rate +
                    'px; left:' +
                    -left / rate +
                    'px; width:' +
                    imgW / rate +
                    'px; height:' +
                    imgH / rate +
                    'px"'
            );
        } else {
            msgArea.push('style="height:' + r * 1.25 + 'px"');
        }

        msgArea.push(' /></div>');
        msgArea.push("<div class='msgCenterBox'>");
        msgArea.push("<div class='userInfoBox'>");
        msgArea.push("<div class='msgUser g_mirrorColor'>&nbsp;" + data.creator + '</div>');
        msgArea.push("<span class='msgLevalUser'></span>");
        msgArea.push('</div>');
        if ('star' in data) {
            msgArea.push("<div class='fk-star-list'>");
            var star = Number(data.star);
            for (var i = 0; i < 5; i++) {
                if (i < star) {
                    msgArea.push("<li class='faisco-icons-star3 select_more'></li>");
                } else {
                    msgArea.push("<li class='faisco-icons-star3 select_less'></li>");
                }
            }
            msgArea.push('</div>');

            if (star == 1) {
                let oldCount = Number(jm('.fk-right-statisBox .badComm').text());
                jm('.fk-right-statisBox .badComm').text(oldCount + 1);
            } else if (star > 3) {
                let oldCount = Number(jm('.fk-right-statisBox .hignComm').text());
                jm('.fk-right-statisBox .hignComm').text(oldCount + 1);
            } else {
                let oldCount = Number(jm('.fk-right-statisBox .middComm').text());
                jm('.fk-right-statisBox .middComm').text(oldCount + 1);
            }

            var avgScore = jm('.fk-comm-avgScore'),
                totalScore = Number(avgScore.attr('_tc')) + star,
                totalSize = Number(avgScore.attr('_ts')) + 1;

            var statisScore = totalScore / totalSize;
            avgScore.text(statisScore.toFixed(1));
            avgScore.attr('_tc', totalScore);
            avgScore.attr('_ts', totalSize);
        }
        msgArea.push('</div>');
        msgArea.push("<div class='msgTime g_mirrorColor'>" + date + '</div>');
        msgArea.push('</div>');
        msgArea.push("<div class='msgContent g_defaultColor'>" + data.comment + '</div>');

        if (typeof data.commImgList != 'undefined' && data.commImgList != 'null') {
            var commImgList = jm.parseJSON(data.commImgList);
            if (commImgList != null && commImgList.length > 0) {
                msgArea.push("<div class='ct-s-content-m'>");
                msgArea.push("<ul style='line-height:0'>");
                for (let i = 0; i < commImgList.length; i++) {
                    msgArea.push("<li class='s-img-f-tb'>");
                    msgArea.push("<table class='s-img-tb' cellpadding='0' cellspacing='0'>");
                    msgArea.push('<tr>');
                    msgArea.push("<td class='s-img-bd' valign='middle'   align='center'>");
                    msgArea.push("<img src='" + commImgList[i].path + "' alt='' class='s-img-set'>");
                    msgArea.push('</td>');
                    msgArea.push('</tr>');
                    msgArea.push('</table>');
                    msgArea.push('</li>');
                }
                msgArea.push('</ul>');
                msgArea.push('</div>');
            }
        }
        msgArea.push('</div>');
        //将新添加商品评论添加到评论列表的顶部
        if (jm('#tabPanel3 .msgArea')[0] != undefined) {
            jm(msgArea.join('')).insertBefore(jm('#tabPanel3 .msgArea')[0]);
            setNewCommImgBindShow();
            if (jm('#tabPanel3 .noCommentListDescribe').length) {
                jm('#tabPanel3 .noCommentListDescribe').parent().remove();
            }
        } else {
            location.reload();
        }
    }
    //关闭评论页面
    jm('#userCommentPanel .userCommentGoBack').click();
};

export const postUserComment = function (urlStr, bssType) {
    var userTabId = document.getElementById('userCommentPanel'),
        tabUl = userTabId.querySelector('.tabUl'),
        bssKey = bssType === 0 ? 'ALrd86EDCAEQARoEWzE3XSIJ5ZSQ5LiW5rCR' : 'ANKUjxIIARABGgRbMTZdIgnllJDkuJbmsJE', //业务类型 0：产品评论 1：文章评论
        callBack = function (target) {
            if (target) {
                var tabLiId = target.getAttribute('data'),
                    currentTabLiId = userTabId.querySelector('.tabActive').getAttribute('data'),
                    tabUserPanel = document.getElementById('tabUserPanel' + tabLiId);
                jm(userTabId.querySelector('.tabActive')).removeClass('tabActive g_main_color_v3 g_main_bdColor_v3');
                jm(userTabId.querySelector('.tabPanelAtive')).removeClass('tabPanelAtive');
                jm('#userCommentRemarkPanel' + tabLiId).append(
                    jm('#userCommentRemarkPanel' + currentTabLiId + ' .g_globalLine')
                );
                jm(tabUserPanel).append(jm('#userCommentRemarkPanel'));
                jm('#tabUserLi' + tabLiId).addClass('tabActive g_main_color_v3 g_main_bdColor_v3');
                jm(tabUserPanel).addClass('tabPanelAtive');
            }
        },
        loginAndComformComment = function (showAfterReview = false) {
            var acct = jm('#user').val();
            var password = jm('#password').val();
            if (acct == null || acct == '') {
                mobiIng(LS.memberInputAcct);
                return;
            }
            if (password == null || password == '') {
                mobiIng(LS.memberInputPwd);
                return;
            }
            password = jm.fai_encrypt(password);

            var loginBssKey = 'AKWWpqEHCAEQARoDWzFdIgnllJDkuJbmsJE';
            var loginAction = function (checkSign) {
                var loginData =
                    'cmd=wafNotCk_loginMember&acct=' + jm.encodeUrl(acct) + '&pwd=' + jm.encodeUrl(password);
                if (checkSign) {
                    loginData += `&bssKey=${loginBssKey}&checkSign=${checkSign}`;
                }
                request
                    .post('/ajax/login_h.jsp', {
                        data: loginData,
                    })
                    .catch(function () {
                        var loginBtn = jm('.loginSubmit');
                        loginBtn.removeAttr('disabled');
                        showMemberLoginMsg(-1);
                    })
                    .then(async (response) => {
                        const { data } = response;
                        if (data.success) {
                            Fai.top.sessionMemberId = data.sessionMemberId;
                            Mobi._needFaitest = false;
                            jm('#_TOKEN').remove();
                            jm('head').append(data._HEADTOKEN);
                            await clueReuqestVm.authRecordLoginOther();
                            ajaxComment(true, !!checkSign, showAfterReview); //在登录这边已经验证过一次了 在评论那里就不再校验滑动验证了
                            return;
                        } else {
                            if (data.active) {
                                mobiIng(LS.memberLoginMailActivation);
                            } else {
                                showMemberLoginMsg(data.rt, data.captcha);
                                if (data.captcha) {
                                    Mobi._needFaitest = true;
                                    loginAndComformComment(showAfterReview);
                                }
                            }
                        }
                    });
            };

            if (Mobi._needFaitest) {
                // eslint-disable-next-line no-undef
                initFaitest(
                    {
                        bss: 1,
                        bssKey: loginBssKey,
                    },
                    function (checkSign) {
                        loginAction(checkSign);
                    }
                );
            } else {
                loginAction();
            }
        },
        // fromLogin 是否来自“登录评论”
        ajaxComment = function (type, fromLogin, showAfterReview = false) {
            var commentsObjId = jm('#postCommentId').val(); //所评论的对象的Id，如商品或者文章
            var comment = jm('#userCommentRemark').val();
            var commentMinLen = jm('#userCommentRemark').attr('minlength');
            var creator;

            if (typeof comment != 'string' || comment == '') {
                mobiIng(LS.commentNotEmpty);
                return;
            }
            if (comment.length < commentMinLen) {
                mobiIng(jm.format(LS.commentLenTips, encodeHtml(commentMinLen)));
                return;
            }
            if (!type) {
                creator = jm.trim(jm('#anonymityCommentUser').val());
                if (typeof creator != 'string') {
                    mobiIng(LS.creatorTips);
                    return;
                }
            } else {
                creator = jm.trim(jm('#user').val());
            }

            // 获取评论上传的图片的路径
            var imgs = jm('.J_productCommImg').find('img');
            var commImgList = [];
            if (imgs != null && typeof imgs != 'undefined') {
                var imgsLength = imgs.length;
                if (imgsLength > 0) {
                    for (var i = 0; i < imgsLength; i++) {
                        var tempImg = imgs.eq(i);
                        if (tempImg != null && tempImg != 'undefined') {
                            var newImg = {};
                            var imgId = tempImg.attr('_id');
                            var imgName = tempImg.attr('_name');
                            var imgSize = tempImg.attr('_file_size');
                            var tempFileId = tempImg.attr('_file_id');
                            var fileWidth = Number(tempImg.attr('_fileWidth') || 0);
                            var fileHeight = Number(tempImg.attr('_fileHeight') || 0);
                            var fileType = Number(tempImg.attr('_fileType') || 0);
                            newImg['imgId'] = imgId;
                            newImg['imgName'] = imgName;
                            newImg['imgSize'] = imgSize;
                            newImg['tempFileId'] = tempFileId;
                            newImg['imgWidth'] = fileWidth;
                            newImg['imgHeight'] = fileHeight;
                            newImg['imgType'] = fileType;
                            commImgList.push(newImg);
                        }
                    }
                }
            }

            var submitData = {
                commentsObjId,
                creator,
                commImgList: JSON.stringify(commImgList),
                comment,
                anonymity: !type,
            };
            if (fromLogin) {
                submitData.fromLogin = true;
            }
            var star;
            if (type) {
                star = jm('.J-star-list').eq(0).attr('_star');
            } else {
                star = jm('.J-star-list').eq(1).attr('_star');
            }

            if (star) {
                submitData.star = star;
            }
            mobiIng(LS.siteFormSubmitIng);

            var commentAction = function (checkSign, showAfterReview = false) {
                if (checkSign) {
                    submitData.checkSign = checkSign;
                    submitData.bssKey = bssKey;
                }

                request
                    .post(urlStr, {
                        data: submitData,
                    })
                    .catch(function () {
                        mobiIng(LS.js_systemError);
                        logMonitor(5933, true);
                    })
                    .then((response) => {
                        const { data } = response;
                        if (!data || !data.success) {
                            if (data.rt === -130) {
                                mobiIng(LS.commentMaintainIng);
                            } else {
                                if (data.captcha) {
                                    Mobi._needFaitest = true;
                                    ajaxComment(type, fromLogin, showAfterReview);
                                }
                            }
                        } else {
                            Mobi._needFaitest = false;
                        }
                        if (data.hasFW) {
                            mobiIng(data.msg);
                            return;
                        }

                        if (data.logMonitor) {
                            logMonitor(5933, true);
                        }

                        switch (data.msg) {
                            case 1: //验证码错误
                                showMemberLoginMsg(-301, data.captcha);
                                mobiIng(LS.js_captchaError);
                                jm('#loginCaptchaImg').click();
                                jm('#loginCaptcha').val('');
                                break;
                            case 2: //姓名错误
                                mobiIng(LS.creatorError);
                                break;
                            case 3: //评论错误
                                mobiIng(LS.commentError);
                                break;
                            case 4: //提交成功
                                if (star) {
                                    data.comment.star = star;
                                }
                                if (Fai.top._openThemeV3) {
                                    addTheNewCommentV3(data.comment);
                                } else {
                                    addTheNewComment(data.comment);
                                }
                                if (showAfterReview === false) {
                                    mobiIng(LS.commentSuccess, 1); // 评论成功
                                } else {
                                    mobiIng(LS.commentSuccessNeedReview, 1); // 评论提交成功，请等待管理员审核
                                }
                                jm('#userCommentRemark').val('');
                                jm('#loginCaptchaImg').click();
                                jm('#loginCaptcha').val('');
                                jm('.comm-find-tb-remove').remove();
                                if (type) {
                                    initStarList(0);
                                } else {
                                    initStarList(1);
                                }
                                logMallFdp(
                                    'mall_consumer_comment',
                                    {
                                        mall_free_text_0: '手机商城',
                                        mall_free_text_1: data.comment.memberId > 0 ? '会员' : '游客',
                                        mall_free_bool_0: data.comment.isOnlyBuy,
                                        mall_free_text_3:
                                            typeof data.comment.star != 'undefined' ? data.comment.star + '' : '',
                                    },
                                    true
                                );
                                //location.reload();
                                break;
                            case 5: //参数错误，请重新填写
                                mobiIng(LS.paramError);
                                break;
                            case 6: //评论仅对会员开通，请登录。
                                mobiIng(LS.OnlyMembersCanComment);
                                callBack(jm('#tabUserLi1')[0]);
                                break;
                            case 7: //评论功能关闭。
                                mobiIng(LS.commentClosed);
                                break;
                            case 8: //系统错误
                                mobiIng(LS.commentSubmitError);
                                break;
                            case 9: //评论数据已超过总数量限制。
                                mobiIng(LS.commentCountLimit);
                                break;
                            case 10: // 提示输入验证码
                                if (bssType === 1) {
                                    // 文章
                                    mobiIng(LS.msgBoardInputValidateCode);
                                    if (data.needCode) {
                                        jm('.J_newsCommentCaptcha').removeClass('captchaHide');
                                    }
                                } else {
                                    Mobi.ing(LS.commentMaintainIng);
                                }
                                break;
                            case 11: // 不符合评论权限
                                mobiIng(data.warningTips);
                                break;
                            default:
                        }
                        return;
                    });
            };

            if (Mobi._needFaitest && !fromLogin) {
                // eslint-disable-next-line no-undef
                initFaitest(
                    {
                        bss: 1,
                        bssKey,
                    },
                    function (checkSign) {
                        commentAction(checkSign, showAfterReview);
                    }
                );
            } else {
                commentAction('', showAfterReview);
            }
        };

    bindTouchAndClickEvent(jm(tabUl), function (e) {
        var target = e.target;
        callBack(target);
    });

    function submitCommit(showAfterReview) {
        if (Fai.top.sessionMemberId > 0) {
            ajaxComment(true, false, showAfterReview);
        } else {
            loginAndComformComment(showAfterReview);
        }
    }

    const messageType =
        jm('.J_productCommImg').length > 0
            ? MOBILE_AUTH_MESSAGE_TYPES.PRODUCT_COMMENT
            : MOBILE_AUTH_MESSAGE_TYPES.NEWS_COMMENT;

    //登录提交
    bindTouchAndClickEvent(jm('#userCommentLoginAndComform'), async function (e) {
        if (VITE_APP_MODE !== 'visitor') {
            mobiIng('您目前处于网站管理状态，请先退出再登录');
            return;
        }

        if (jm('.js_newsDetailCommitDisabled').length > 0) {
            return;
        }

        e.stopPropagation();

        const mobileAuthPopupService = new MobileAuthPopupService(getThemeColor());
        const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(messageType);
        if (needAuth) {
            mobileAuthPopupService.createSubmitHandler(
                () => {
                    submitCommit(showAfterReview);
                },
                (err) => {
                    mobiIng(err.msg);
                }
            );
            mobileAuthPopupService.show();
        } else {
            submitCommit(showAfterReview);
        }
        return false;
    });
    //匿名提交
    bindTouchAndClickEvent(jm('#anonymityCommentComform'), async function (e) {
        if (VITE_APP_MODE !== 'visitor') {
            mobiIng('您目前处于网站管理状态，请先退出再登录');
            return;
        }

        const mobileAuthPopupService = new MobileAuthPopupService(getThemeColor());
        const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(messageType);
        if (needAuth) {
            mobileAuthPopupService.createSubmitHandler(
                () => {
                    ajaxComment(false, false, showAfterReview);
                },
                (err) => {
                    mobiIng(err.msg);
                }
            );
            mobileAuthPopupService.show();
        } else {
            ajaxComment(false, false, showAfterReview);
        }
        e.stopPropagation();
        return false;
    });
};

/**
 * @description 渲染文章评论区 （改造getCommentsInfo，由jsp输出的html改为vue渲染）
 * @author json
 * @date 20/07/2021
 * @export
 * @param {*} [params={}]
 * @return {*}
 */
export const getNewForCommentsInfo = async function ({ id, pageno, moduleId, isV3, from }) {
    if (isNaN(id)) {
        ing(LS.mallStlSubmitError);
        return;
    }

    let sendData = {
        id,
        pageno,
    };

    showLoading();
    let result = await getNewsV3Comment(sendData);
    let { data, success } = result;
    if (success) {
        let { totalSize } = data;
        renderNewsComment({ ...data, moduleId, id });
        setTimeout(function () {
            bindImgLazyLoad('newsDetailPanel');
        }, 0);
        closeLoading();
        hidePlaceholder();
        // 3.0 版本先去掉该逻辑，想找下场景。找到再处理 ---- cys
        if (!isV3) {
            // 修复如果当文章详情的高度小于body的高度是，显示不了去评论的
            if (jm('#postComment').length > 0 && jm('#module' + moduleId).length > 0) {
                var g_web_height = jm('#g_web').height();
                if (g_web_height - jm('#webContainerBox').height() > 5) {
                    jm('#webContainerBox').css('height', g_web_height + 'px');
                    jm('#module' + moduleId)
                        .css('height', g_web_height + 'px')
                        .find('.formMiddle')
                        .css('height', g_web_height + 'px');
                }
            }
        }

        if (Fai.top._colId == COL.SYS_NEWS_DETAIL_V3 && Fai.top._openThemeV3) {
            // 追加评论图标
            if (jm('body').find('#newsDatailComment').length <= 0) {
                if (jm('body').find('#newsDetailShare').length) {
                    jm("<span id='newsDatailComment' class='icon-comment'></span>").insertBefore('#newsDetailShare');
                } else {
                    jm('.newsBottom').append("<span id='newsDatailComment' class='icon-comment'></span>");
                }
            }
        }

        // 在评论图标追加总评论数
        if (totalSize > 0) {
            var circle_size = totalSize < 10 ? 'circle_size' : ' '; //区分评论数是一位数还是两位数的样式
            var $newsDatailComment = jm('#newsDatailComment');
            $newsDatailComment.append("<span class='comment_totalSize " + circle_size + "'>" + totalSize + '</span>');

            // 绑定滚动事件
            jm('#newsDatailComment').bind('click', function () {
                var headerHeight = 0; //常规顶部是fixed ，所以滚动的时候减去顶部的高度
                if (jm('#g_body').hasClass('regularHeadStyle') && jm('.productDetailHeader').css('display') != 'none') {
                    //常规顶部
                    if (jm('.productDetailHeader').length) {
                        headerHeight = jm('.productDetailHeader').height();
                    }
                }

                var scrollTop = $('.commentBox').position().top - headerHeight;
                var scrollTopMin = parseInt(scrollTop) - 10;
                var scrollTopMax = parseInt(scrollTop) + 10;
                if (
                    document.documentElement.scrollTop > scrollTopMin &&
                    document.documentElement.scrollTop < scrollTopMax
                ) {
                    return;
                }
                var speed;
                var timer;
                var scrollTopTmp = scrollTop;
                var sum = 0;
                timer = setInterval(function () {
                    speed = scrollTopTmp / 4;
                    scrollTop = parseInt(sum + speed);
                    sum = scrollTop;
                    jm('#g_web')[0].scrollTop = scrollTop;
                    if (scrollTop >= scrollTopTmp - speed) {
                        clearInterval(timer);
                    }
                }, 1000 / 60);
            });
        } else {
            jm('#newsDatailComment .comment_totalSize').remove();
        }

        if (from == FROM_CHANGE_PAGE) {
            let headerHeight = 0;
            if (jm('#g_body').hasClass('regularHeadStyle') && jm('.productDetailHeader').css('display') != 'none') {
                //常规顶部
                headerHeight = jm('.productDetailHeader').height();
            }

            jm('#g_web')[0].scrollTop = jm('.commentBox').offset().top - headerHeight;
        }
    }
};
let CommentsVueApp = null;
function renderNewsComment({ newsCommentList, pageno, totalPage, totalSize, moduleId, id }) {
    if (CommentsVueApp != null && jm('.commentBox').length) {
        CommentsVueApp.newsCommentList = newsCommentList;
        CommentsVueApp.pageno = pageno;
        CommentsVueApp.totalPage = totalPage;
        CommentsVueApp.totalSize = totalSize;
        return;
    }

    let CommentsVue = Vue.extend({
        components: {
            pagenation,
        },
        data() {
            return {
                totalSize,
                newsCommentList,
                pageno,
                totalPage,
                moduleId,
                openThemeV3: Fai.top._openThemeV3,
                loadingPath: Fai.top._loadingPath,
                mobi_Comments_List: LS.mobi_Comments_List,
                hasNoComment: LS.hasNoComment,
                resRoot: Fai.top._resRoot,
            };
        },
        computed: {
            hasComments() {
                return this.totalSize > 0;
            },
            msg_time_r() {
                return this.openThemeV3 ? 'msg_time_r' : '';
            },
            hideCommentHead() {
                // SYS_NEWS_NEW_DETAIL 的详情页，如果评论数是0就不展示
                return !this.hasComments && [COL.SYS_NEWS_NEW_DETAIL, COL.SYS_NEWS_DETAIL_V3].includes(Fai.top._colId);
            },
        },
        methods: {
            getNoCreatorId(creatorId) {
                return this.openThemeV3 && creatorId == 0 ? 'noCreatorId' : ''; //区分游客和VIP的样式
            },
            getCreatorType(creatorId) {
                return creatorId == 0 ? LS.visitor : 'VIP';
            },
            changePage(pageno) {
                this.pageno = pageno;
                getNewForCommentsInfo({
                    id,
                    pageno,
                    moduleId: this.moduleId,
                    isV3: true,
                    from: FROM_CHANGE_PAGE,
                });
            },
        },
        template: `
            <div class='commentBox'>
                <div class='newsV3ComHeader' v-if="!hideCommentHead">
                    <span>{{mobi_Comments_List}}<span class='totalSize'>{{totalSize}}</span></span>
                </div>

                <div class='comment'>
                    <template v-if="hasComments"><!-- 评论下 -->
                        <div v-for="(info, index) in newsCommentList" :key="'comments' + index" class='msgArea'>
                            <template v-if="openThemeV3">
                                <div class='user_flex_wrap'>
                                    <div class='userIcon'><img class='J_img_lazyload' :src-original='info.imgPath' :src="loadingPath"/></div>
                                    <div class='infoArea'>
                                        <div class='msgTitle'>
                                            <div class='user_and_type'>
                                                <div class='msgUser'>{{info.creator}}</div>
                                                <div v-if="info.creatorId == 0" :class="getNoCreatorId(info.creatorId)" class='creatorType'> [{{getCreatorType(info.creatorId)}}]</div>
                                                <div v-else :class="getNoCreatorId(info.creatorId)" class='creatorType g_mainColor'>{{getCreatorType(info.creatorId)}}</div>
                                            </div>
                                            <div :class="msg_time_r" class='msgTime' >{{info.time}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class='msgContent' v-html="info.comment"></div>
                            </template>
                            <template v-else>
                                <div class='userIcon'><img class='J_img_lazyload' :src-original='info.imgPath' :src="loadingPath"/></div>
                                <div class='infoArea'>
                                    <div class='msgTitle'>
                                        <div class='msgUser'>{{info.creator}}</div>
                                        <div v-if="info.creatorId == 0" :class="getNoCreatorId(info.creatorId)" class='creatorType'> [{{getCreatorType(info.creatorId)}}]</div>
                                        <div v-else class='creatorType g_mainColor'> [{{getCreatorType(info.creatorId)}}]</div>
                                        <div :class="msg_time_r" class='msgTime' >{{info.time}}</div>
                                    </div>
                                    <div class='msgContent' v-html="info.comment"></div>
                                </div>
                            </template>
                        </div>
                    </template>

                    <template v-if="!hasComments && openThemeV3"><!-- 无评论下 -->
                        <div class='no_detail_img_wrap'>
                            <div class='no_detail_img'>
                                <img :src='resRoot + "/image/v2/defaultIcon04.png?v=201811291709"' alt=''>
                            </div>
                            <p class='no_detail_text'>{{hasNoComment}}</p>
                        </div>
                    </template>
                </div>

                <pagenation
                    v-if="totalPage > 1"
                    :module-id="moduleId"
                    :total-size="totalSize"
                    :page-size="10"
                    :pageno="pageno"
                    :open-theme-v3="openThemeV3"
                    :ajax-pagenation="true"
                    @on-change="changePage"
                    @click.native.stop=""
                >
                </pagenation>
            </div>
        `,
    });
    CommentsVueApp = new CommentsVue().$mount('#renderInNewsDetailComment');
}

/* get  user comments information*/
export const getCommentsInfo = function (cmd, id, pageno, moduleId) {
    if (!cmd) {
        mobiIng(LS.mallStlSubmitError);
        return;
    }

    if (isNaN(id)) {
        mobiIng(LS.mallStlSubmitError);
        return;
    }

    var sendData = 'id=' + id;
    if (!isNaN(pageno)) {
        jm('.getMoreComments').remove();
        sendData += '&pageno=' + pageno;
    }
    if (cmd == 'getWafNotCk_newsV3') {
        sendData += '&loadingPath=' + Fai.top._loadingPath;
    }
    // Mobi.mobiHideScroll();
    showLoading();
    jm.ajax({
        type: 'post',
        url: '/ajax/other_h.jsp?cmd=' + cmd,
        data: sendData,
        error() {
            closeLoading();
            mobiIng(LS.js_systemError);
        },
        success(result) {
            var data = jm.parseJSON(result);
            if (data.success) {
                jm('.commentBox').remove();
                jm('#newsDetailPanel').append(data.html);
                if (cmd == 'getWafNotCk_newsV3') {
                    setTimeout(function () {
                        bindImgLazyLoad('newsDetailPanel');
                    }, 0);
                }
            }
            closeLoading();
            hidePlaceholder();
            // 3.0 版本先去掉该逻辑，想找下场景。找到再处理 ---- cys
            if (cmd !== 'getWafNotCk_newsV3') {
                // 修复如果当文章详情的高度小于body的高度是，显示不了去评论的
                if (jm('#postComment').length > 0 && jm('#module' + moduleId).length > 0) {
                    var g_web_height = jm('#g_web').height();
                    if (g_web_height - jm('#webContainerBox').height() > 5) {
                        jm('#webContainerBox').css('height', g_web_height + 'px');
                        jm('#module' + moduleId)
                            .css('height', g_web_height + 'px')
                            .find('.formMiddle')
                            .css('height', g_web_height + 'px');
                    }
                }
            }
        },
    });
};

const initStarList = function (flag) {
    jm('.J-star-list').eq(flag).attr('_star', 5);
    jm('.J-star-list')
        .eq(flag)
        .html(
            '<li class="faisco-icons-star3"></li><li class="faisco-icons-star3"></li><li class="faisco-icons-star3"></li><li class="faisco-icons-star3"></li><li class="faisco-icons-star3"></li><li class="score-tip"></li>'
        );
};

export function getCommentDiv(cmd, id, pageno) {
    if (!cmd) {
        ing(LS.mallStlSubmitError);
        return;
    }

    if (isNaN(id)) {
        ing(LS.mallStlSubmitError);
        return;
    }

    if (jm('#comemtDiv').length === 0) {
        jm('#fullScreenDivCotainer').append(
            "<div class='fullScreenDiv webContainerBox moduleContent'  style='overflow-y: scroll;background:#fff;' id='comemtDiv'>" +
                '</div>'
        );
    } else {
        jm('#comemtDiv').html('');
    }

    var sendData = 'id=' + id;
    if (!isNaN(pageno)) {
        sendData += '&pageno=' + pageno;
    }
    mobiHideScroll();
    showLoading();
    var r = jm('html').css('font-size');
    r = r.substring(0, r.length - 2);
    jm.ajax({
        type: 'post',
        url: addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/other_h.jsp?cmd=' + cmd + '&rem=' + r,
        data: sendData,
        error() {
            closeLoading();
            ing(LS.js_systemError);
        },
        success(result) {
            var data = jm.parseJSON(result);
            if (data.success) {
                jm('#comemtDiv').append(data.html);
                jm('#comemtDiv').addClass('showFullScreen');
            }
            closeLoading();
            hidePlaceholder();

            setTimeout(function () {
                //划动
                var listNumObject = jm('.ct-s-content-m');
                if (typeof listNumObject != 'undefined' && listNumObject != null) {
                    for (var i = 0; i < listNumObject.length; i++) {
                        var images = listNumObject.eq(i)[0].querySelectorAll('img');
                        if (typeof images != 'undefined' && images != null && images.length > 0) {
                            bindCommImgShow(images);
                        }
                    }
                }
            }, 100);
        },
    });
}

export const removeCommentDiv = function () {
    mobiShowScroll();
    jm('#comemtDiv').remove();
    window.location.reload();
};

function _newsCommentAddCom(showAfterReview = false) {
    var creator = jm.trim(jm('#newsCommentCreator').val());
    var comment = jm('#newsCommentInput').val();
    var validateCode = jm('#validateCodeInput').val();
    var newsId = jm('#newsCommentInput').attr('newsId');

    var urlStr =
        addRequestPrefix({ newPath: '/ajax', oldPath: 'ajax' }) + '/newsComment_h.jsp?cmd=addWafCk_submitComment';
    var submitData = {
        validateCode,
        newsId,
        creator,
        comment,
    };

    ing(LS.siteFormSubmitIng);
    //已限制了输入  不必检验最大长度
    jm.ajax({
        type: 'POST',
        url: urlStr,
        data: submitData,
        error() {
            ing(LS.js_systemError);
            jm('#captchaChange').click();
        },
        success(result) {
            var data = jm.parseJSON(result);
            if (!data || !data.success) {
                jm('#captchaChange').click();
            }

            if (data.hasFW) {
                ing(data.msg);
                return;
            }

            switch (data.msg) {
                case 1: //验证码错误
                    ing(LS.js_captchaError);
                    break;
                case 2: //姓名错误
                    ing(LS.creatorError);
                    break;
                case 3: //评论错误
                    ing(LS.commentError);
                    break;
                case 4: //提交成功
                    getCommentDiv('getWafNotCk_news', newsId);
                    if (showAfterReview === false) {
                        ing(LS.commentSuccess, 1); // 评论成功
                    } else {
                        ing(LS.commentSuccessNeedReview, 1); // 评论提交成功，请等待管理员审核
                    }
                    break;
                case 5: //参数错误，请重新填写
                    ing(LS.paramError);
                    break;
                case 6: //评论仅对会员开通，请登录。
                    var turnToUrl = encodeUrl(window.location.href);
                    window.location.href =
                        preprocessUrl({ path: '', oldPath: '' }) + 'login.jsp?returnUrl=' + turnToUrl + '&errno=11';
                    break;
                case 7: //评论功能关闭。
                    ing(LS.commentClosed);
                    break;
                case 8: //系统错误
                    ing(LS.commentSubmitError);
                    break;
                case 9: //评论数据已超过总数量限制。
                    ing(LS.commentCountLimit);
                    break;
                case 10: // 提示输入验证码
                    ing(LS.msgBoardInputValidateCode);
                    if (data.needCode) {
                        jm('.J_newsCommentCaptcha').removeClass('captchaHide');
                    }
                    break;
                default:
            }
        },
    });
}

export const newsCommentAddCom = async function () {
    var creator = jm.trim(jm('#newsCommentCreator').val());
    var comment = jm('#newsCommentInput').val();
    var commentMinLen = jm('#newsCommentInput').attr('minlength');
    var validateCode = jm('#validateCodeInput').val();

    if (typeof creator != 'string') {
        ing(LS.creatorTips);
        return;
    }

    if (typeof comment != 'string' || comment == '') {
        ing(LS.commentNotEmpty);
        return;
    }
    if (comment.length < commentMinLen) {
        ing(jm.format(LS.commentLenTips, jm.encodeHtml(commentMinLen)));
        return;
    }

    if (!jm('.J_newsCommentCaptcha').hasClass('captchaHide')) {
        if (typeof validateCode != 'string' || validateCode == '') {
            ing(LS.memberInputCaptcha);
            return;
        }
    }

    const mobileAuthPopupService = new MobileAuthPopupService(getThemeColor());
    const { needAuth, showAfterReview } = await mobileAuthPopupService.needAuth(MOBILE_AUTH_MESSAGE_TYPES.NEWS_COMMENT);
    if (needAuth) {
        mobileAuthPopupService.createSubmitHandler(
            () => {
                _newsCommentAddCom(showAfterReview);
            },
            (err) => {
                mobiIng(err.msg);
            }
        );
        mobileAuthPopupService.show();
    } else {
        _newsCommentAddCom(showAfterReview);
    }
};
